body {
  font-family: sans-serif;
}

body,
html {
  background-color: #e5f0f1 !important;
  height: 100%;
}

* {
  overscroll-behavior: none;
}

#destination_step.hide,
#feedbackH5.hide,
#noresult.hide,
.container-filter.hide,
#input_keyword.hide,
#helpmsg.hide,
#backArrow.hide,
#divsvg.hide,
.select_wrap.hide,
#filter-fuel.hide,
#filter-boite.hide,
#filter-power.hide,
.select_card_version.hide,
#home.hide,
#infos.hide,
#home.hide,
#etapes.hide,
#footer.hide,
#result.hide,
#container-immat.hide,
#bouton_valider.hide,
#method-select-vehicle.hide,
#backArrow.hide {
  display: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 2;
}

.show {
  display: block;
}

.grecaptcha-badge {
  visibility: hidden;
}

.text-center {
  text-align: center;
}
