body {
  font-family: sans-serif;
}

body, html {
  height: 100%;
  background-color: #e5f0f1 !important;
}

* {
  overscroll-behavior: none;
}

#destination_step.hide, #feedbackH5.hide, #noresult.hide, .container-filter.hide, #input_keyword.hide, #helpmsg.hide, #backArrow.hide, #divsvg.hide, .select_wrap.hide, #filter-fuel.hide, #filter-boite.hide, #filter-power.hide, .select_card_version.hide, #home.hide, #infos.hide, #home.hide, #etapes.hide, #footer.hide, #result.hide, #container-immat.hide, #bouton_valider.hide, #method-select-vehicle.hide, #backArrow.hide {
  display: none;
}

.main-container {
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-around;
  display: flex;
}

.show {
  display: block;
}

.grecaptcha-badge {
  visibility: hidden;
}

.text-center {
  text-align: center;
}

/*# sourceMappingURL=index.5df725d0.css.map */
